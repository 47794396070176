let albumData = [
    {
        title:"Home Soon - Single",
        albumArt:"/assets/homesoon_web.jpg",
        spotify: "https://open.spotify.com/track/1Pe0sKtthT1kb2D9knxMuh?si=b33bba0ffbf34c4a",
        appleMusic: "https://music.apple.com/us/album/home-soon/1592431903?i=1592431904",
        soundCloud:"https://soundcloud.com/andeverythingbetween/home-soon?si=0d7b2f4bd06343b6b048f130b30f0b5d",
        bandCamp:"https://andeverythingbetween.bandcamp.com/track/home-soon"
    },
    {
        title:"Ghost of Me - Single",
        albumArt:"/assets/ghost_of_me_web.jpg",
        spotify:"https://open.spotify.com/track/588U4ouKfnZdzBCLjR6K5L?si=c54256b217674772",
        appleMusic:"https://music.apple.com/us/album/ghost-of-me/1592431769?i=1592431770",
        soundCloud:"https://soundcloud.com/andeverythingbetween/ghost-of-me?si=f8ac8a6bd3184c208509860ca64d1f00",
        bandCamp:"https://andeverythingbetween.bandcamp.com/track/ghost-of-me"
    },
    {
        title:"Shaking Off Yesterday - Single",
        albumArt:"/assets/shakingoffyesterday_web.jpg",
        spotify:"https://open.spotify.com/track/3sTwBNRjq7QJSO2CLbzNEd?si=42f17187a2004d24",
        appleMusic:"https://music.apple.com/us/album/shaking-off-yesterday/1591736205?i=1591736207",
        soundCloud:"https://soundcloud.com/andeverythingbetween/shaking-off-yesterday?si=47440dfd10fc4376a4d550246ab8304f",
        bandCamp:"https://andeverythingbetween.bandcamp.com/track/shaking-off-yesterday"
    }
];

export default albumData;