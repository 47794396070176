import React from "react";
import './home.scss';

import { Header, Footer, Hero, About, News} from "../../components";

export default function Home(){
    return(
      <div className="App">
        <div className="page-home">
          <Header />
          <Hero backgroundImg="/assets/treecan_hero.png" artistName="Tree Can Records Logo"/>
          <section className="page-home-extra"></section>
          <main>
          <News>
              <div style={{display:"flex", flexDirection:'row', paddingBottom:"2rem"}}>
                  <div style={{width:150}}><img src="/assets/lwolove.jpg" style={{width:150, height:"auto"}} alt="O Love - Single" /></div>
                  <div style={{width:"55%", paddingLeft:"2rem"}}><p><b style={{fontWeight:"bold"}}>O Love</b> - New single from upcoming album <i style={{fontStyle:"italic"}}>You Don't Carry It Alone</i> - now available on <a href="https://music.apple.com/us/album/o-love-single/1676572765">Apple Music</a>, <a href="https://open.spotify.com/track/2kg1ZfDUEf9HQqcBgyqZ8M?si=c7ff2ced385b49a1">Spotify</a>, and <a href="https://leilaway.bandcamp.com/track/o-love">Band Camp</a>.</p></div>
              </div>
          </News>
            <section className="artists">
            <h5 className="section-title">Artists</h5>
              <div className="artists-list">
                <a href="/leilaway">
                  <img src="/assets/leilaway.jpg"/>
                  <aside>
                    <h4>Leila Way</h4>
                  </aside>
                </a>
                <a href="/andeverythingbetween">
                  <img src="/assets/aeb_artist_image.jpg"/>
                  <aside>
                    <h4>And Everything Between</h4>
                  </aside>
                </a>
                <a>
                  <img src="/assets/bloom.png"/>
                  <aside>
                    <h4></h4>
                  </aside>
                </a>
              </div>
            </section>
            <About artistImage="/assets/treecan_hero.png" artistName="Tree Can Records">
                <article>
                  <p>Tree Can Records was formed 2021 out of the pandemic world where creative outlets for many became a necessity. It is a label where artists of all genres are encouraged to grow from humble beginnings.</p>
                  <p>The name, Tree Can Records, comes from a true story of a tree that was gifted to a family in a coffee can for a new home that had no shade around it. Since being planted, the tree has provided four generations of the same family hundreds of pecans.</p>
                </article>
            </About>
          </main>
         <Footer />
        </div>
      </div>
    )
  }