import React from "react";
import './about.scss'

type AboutProps = {
    artistImage: string;
    artistName: string;
    children?:
      | React.ReactChild
      | React.ReactChild[];
  };

function About(props:AboutProps){
    let {artistName, artistImage, children} = props;
    return(
        <section className="about">
            <h5 className="section-title on_primary">About</h5>
            <div className="about-content">
            <article>
                {children}
            </article>
            <div className="about-image">
                <img src={artistImage} alt={artistName}/>
            </div>
            </div>
        </section>
    )
}

export default About;