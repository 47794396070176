const albumData = [
    {
        title:"O Love - Single",
        albumArt:"/assets/lwolove.jpg",
        spotify:"https://open.spotify.com/track/2kg1ZfDUEf9HQqcBgyqZ8M?si=c7ff2ced385b49a1",
        appleMusic:"https://music.apple.com/us/album/o-love-single/1676572765",
        bandCamp:"https://leilaway.bandcamp.com/track/o-love"
    },
    {
        title:"Every Good And Perfect Gift",
        albumArt:"/assets/lwask.jpg",
        spotify:"https://open.spotify.com/album/57lIlZ6U3ZGcpTGErkjRzI?si=fHw8MUYWQGaoM_HbfnEKtg",
        appleMusic:"https://music.apple.com/us/album/every-good-and-perfect-gift-ep/1609064483",
        bandCamp:"https://leilaway.bandcamp.com/track/gloria"
    },
    {
        title:"Gloria - Single",
        albumArt:"/assets/lwgloria.jpg",
        spotify:"https://open.spotify.com/track/3x51dUARmCFFmWn253kH22?si=56c31217dcaa4233",
        appleMusic:"https://music.apple.com/us/album/gloria-single/1605620278",
        bandCamp:"https://leilaway.bandcamp.com/track/gloria"
    },
    {
        title:"Jesus Prayer - Single",
        albumArt:"/assets/jesusprayer.jpg",
        spotify:"https://open.spotify.com/track/77g0NZ10rNdflt8Tw8b6vF?si=e866944651a641cf",
        appleMusic:"https://music.apple.com/us/album/jesus-prayer/1558614156?i=1558614158",
        bandCamp:"https://leilaway.bandcamp.com/track/jesus-prayer"
    }
]

export default albumData;