import React from "react";
import './footer.scss';

function Footer(){
    return (
        <footer>
            <h5 className="section-title on_dark">Contact</h5>
            <p> </p>
            <p className="on_dark">Find Tree Can Records on <a href="https://instagram.com/treecanrecords">Instagram</a></p>
            <p> </p>
            <p> </p>
            <p className="on_dark">Michael Way - Founder / Director of A & R</p>
            <p className="on_dark"><a href="mailto:hello@treecanrecords.com">Hello@treecanrecords.com</a></p>
            <p> </p>
            <p> </p>
            <p className="copyright on_dark">© 2021 Tree Can Records</p>
          </footer>
    )
}

export default Footer;