import React from "react";
import './spotifyPlayer.scss';

type SpotifyProps = {
    trackId:string;
}

export default function SpotifyPlayer(props:SpotifyProps){
    return(
        <div className="spotify-container">
            <iframe src={`https://open.spotify.com/embed/track/${props.trackId}`} width="100%" height="80" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        </div>
    )
}