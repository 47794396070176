import React from "react";
import './news.scss';

type NewsProps = {
    children?:
      | React.ReactChild
      | React.ReactChild[];
  };

export default function News(props:NewsProps){
    return (
        <section className="news">
            <h5 className="section-title on_primary">News</h5>
            <div className="news-content">
                {props.children}
            </div>
        </section>
    )
}