import {useRoutes} from 'hookrouter';

import Home from "./pages/home";
import AndEverythingBetween from './artists/andeverythingbetween';
import LeilaWay from './artists/leilaway';

const routes = {
    '/': () => <Home />,
    '/andeverythingbetween': () => (<AndEverythingBetween/>),
    '/leilaway': () => (<LeilaWay/>)
};

export default function Router() {
    const router = useRoutes(routes)

    return router || <h1>Not Found</h1>
}