import React from "react";
import Album from '../album';

import './albums.scss';

type album = {
        title: string, 
        albumArt: string, 
        spotify?: string, 
        appleMusic?: string, 
        soundCloud?: string, 
        bandCamp?: string 
};

type AlbumProps = {
    albums:album[];
}

export default function Albums(props:AlbumProps){
    return(
        <div className="album_grid">
            {props.albums.map((a:album, i:number)=>(
                <Album 
                    title={a.title} 
                    albumArt={a.albumArt}
                    spotify={a.spotify || ""}
                    appleMusic={a.appleMusic || ""}
                    soundCloud={a.soundCloud || ""}
                    bandCamp={a.bandCamp || ""}
                />
            ))}
        </div>
    )
}