import React, { Children } from 'react';
import './leilaway.scss';
import {Header, Footer, About, Hero, Albums, News, SpotifyPlayer} from '../../components';
import albumData from './albumsData';

function LeilaWay(){
    let artistName = "Leila Way";
    let background = "/assets/leila_header.jpg";
    return(
      <div className="App">
        <div className="page-artist leilaway">
            <Header />
            <Hero backgroundImg={background} artistName={artistName}/>
            <main>
                <News>
                    <div style={{display:"flex", flexDirection:'row', paddingBottom:"2rem"}}>
                        <div style={{width:150}}><img src="/assets/lwolove.jpg" style={{width:150, height:"auto"}} alt="O Love - Single" /></div>
                        <div style={{width:"55%", paddingLeft:"2rem"}}><p><b style={{fontWeight:"bold"}}>O Love</b> - New single from upcoming album <i style={{fontStyle:"italic"}}>You Don't Carry It Alone</i> - now available on <a href="https://music.apple.com/us/album/o-love-single/1676572765">Apple Music</a>, <a href="https://open.spotify.com/track/2kg1ZfDUEf9HQqcBgyqZ8M?si=c7ff2ced385b49a1">Spotify</a> and <a href="https://leilaway.bandcamp.com/track/o-love">Band Camp</a>.</p></div>
                    </div>
                </News>
                <About artistImage="/assets/leila_about.png" artistName={artistName}>
                    <div>
                        <p>Leila Way grew up in a musical family in Austin TX, where she spent her free time poring over Beatles lyrics and dancing to Stevie Ray Vaughn. So it was only natural that she would begin writing her own songs as soon as she was old enough to pick out a melody on the piano.</p>
                        <p>Life and faith brought her here, where she is making music for the Church, with the simple hope of encouraging others on this Pilgrim Journey.</p>
                        <p></p>
                        <p>
                            <a href="http://leilawaymusic.com" target="_blank">LeilaWayMusic.com</a>
                        </p>
                    </div>
                </About>
                <section className="discography">
                    <h5 className="section-title">Tracks & Albums</h5>
                    <Albums albums={albumData} />
                </section>
            </main>
            <Footer />
        </div>
      </div>
    )
  }

  export default LeilaWay;
