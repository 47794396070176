import React from "react";
import './hero.scss';

function Hero(props:{backgroundImg:string, artistName:string}){
    let {backgroundImg, artistName} = props;
  return(
      <>
          <div className="hero-pad"></div>
          <section className="hero">
              <div className="hero-container" style={{ backgroundImage: `url(${backgroundImg})` }}>{artistName}</div>
          </section>
      </>
  )
}

export default Hero;