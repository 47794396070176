import React from "react";

type AlbumProps = {
    title: string;
    albumArt: string;
    spotify?:string;
    appleMusic?:string;
    soundCloud?:string;
    bandCamp?:string;
}

function Album(props:AlbumProps){
  return(
      <article>
          <div className="album_art">
              <img src={props.albumArt} alt={props.title} />
          </div>
          <h6 className="album_title">{props.title}</h6>
          <ul className="album_links">
              {props.spotify &&
                  <li>
                      <a href={props.spotify}>Spotify</a>
                  </li>
              }
              {props.appleMusic && 
                  <li>
                      <a href={props.appleMusic}>Apple Music</a>
                  </li>
              }
              {props.soundCloud && 
                  <li>
                      <a href={props.soundCloud}>SoundCloud</a>
                  </li>
              }
              {props.bandCamp && 
                  <li>
                      <a href={props.bandCamp}>Band Camp</a>
                  </li>
              }
          </ul>
      </article>
  )
}

export default Album;