import React from 'react';
import './header.scss';

function Header(){
    return(
        <header className="primary-header">
            <a href="/">
                <h5 className="logo_text">Tree Can Records</h5>
            </a>
        </header>
    )
}

export default Header;