import React, { Children } from 'react';
import './andeverythingbetween.scss';
import {Header, Footer, About, Hero, Albums, SpotifyPlayer} from '../../components';
import albumData from './albumData';

function AndEverythingBetween(){
    let artistName = "And Everthing Between";
    let background = "/assets/andeverythingbetween_header.jpg";
    return(
      <div className="App">
        <div className="page-artist andeverythingbetween">
            <Header />
            <Hero backgroundImg={background} artistName={artistName}/>
            <main>
                <About artistImage="/assets/aeb_artist_image.jpg" artistName={artistName}>
                    <div>
                        <p>With over 20 years together there is no shortage of laughs, losses, celebrations, and heartache.</p>
                        <p>This project is an acknowledgment of the trials, triumphs, and everything between.</p>
                        <p></p>
                        <p></p>
                        <p className="sub_header">And Everything Between can be found on:</p>
                        <p>
                            <a href="https://open.spotify.com/artist/1JyfrmkS9BwSMAnC9eZ9vc?si=nqJ1Zk7MSH21vqAaGQ9u7w">Spotify</a> 
                            <a href="https://music.apple.com/us/artist/and-everything-between/1591736206">Apple Music</a> 
                            <a href="https://www.instagram.com/andeverythingbetweenofficial/">Instagram</a>
                        </p>                       
                    </div>
                </About>
                <section className="discography">
                    <h5 className="section-title">Tracks & Albums</h5>
                    <SpotifyPlayer trackId="3sTwBNRjq7QJSO2CLbzNEd?theme=0" />
                    <Albums albums={albumData} />
                </section>
            </main>
            <Footer />
        </div>
      </div>
    )
  }

  export default AndEverythingBetween;
